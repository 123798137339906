.react-datepicker {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
  overflow: hidden;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  height: 2rem;
  display: block;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 2rem;
  border: 1px solid;
  border-radius: 0.5rem;
  border-color: #9fa1a4;
  background-color: white;
  padding: 0.5rem 1rem;
}

.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;

  &:hover {
    background: #edf2f7;
  }
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected:hover {
  color: black;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: rgb(230, 30, 40);
  font-weight: normal;

  &:hover {
    background: rgb(240, 40, 40);
  }
}
